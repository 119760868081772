<template >
  <div>
    <div class="row">
      <div class="col-md-12">
        <v-card v-if="selected == null" style="margin-bottom: 30px">
          <v-card-title>
            Jobs Paid
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            @click:row="clicked"
            :headers="headers"
            :items="homes"
            :search="search"
            style="cursor: pointer;"
          ></v-data-table>
        </v-card>
      </div>
      <div class="col-md-12" v-if="selected">
        <OrderInfo :idOrder="selected.id"></OrderInfo>
        <b-button
          type="button"
          @click="selected = null"
          variant="danger"
          style="margin-right: 20px;"
        >Back</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import OrderInfo from "@/view/content/OrderInfo.vue";

export default {
  name: "jobs-paid",
  methods: {
    clicked(value) {
      this.selected = value;
      console.log(this.selected);
    },

    approve(id) {
      fetch(
        "https://cleancity.krueltech.com/dev/api/api.php?action=approveNewHome&id=" + id
      )
        .then(window.location.reload())
        .catch(console.log);
    },

    recuse(id) {
      fetch(
        "https://cleancity.krueltech.com/dev/api/api.php?action=refuseNewHome&id=" + id
      )
        .then(window.location.reload())
        .catch(console.log);
    }
  },
  components: {
    OrderInfo
  },
  data() {
    return {
      homes: [],
      worker: [],
      workerBackup: [],
      orderAtual: null,
      fields: ["name", "city", "address", "type"],
      form: {
        email: "",
        name: "",
        registrationDate: "",
        food: null,
        checked: []
      },
      search: "",
      search2: "",
      selected: null,
      headers: [
        { text: "ID Order", value: "id" },
        { text: "Independent Contractor", value: "worker" },
        { text: "Client", value: "client" },
        { text: "Date Schedule", value: "date" }
      ]
    };
  },
  mounted() {
    var auth = localStorage.getItem("name");
    if (auth != null && auth != "undefined") {
      console.log("existe");
    } else {
      console.log("nao existe");
      this.$router.push({ name: "login" });
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Tables", route: "simple-tables" },
      { title: "Data Tables" }
    ]);

    fetch("https://cleancity.krueltech.com/dev/api/api.php?action=readPaid")
      .then(res => res.json())
      .then(data => {
        this.homes = data.paid;
        //console.log('RES: '+ JSON.stringify(data.independent));
      })
      .catch(console.log);
  }
};
</script>
